import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Box,
  Typography,
  Switch,
  Button,
  TextField,
} from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import styles from "./ModalEnviarContrapropuesta.module.css";
import { IoMdSend } from "react-icons/io";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxHeight: "80vh",
  overflowY: "auto",
  backgroundColor: "white",
  boxShadow: "24px 24px 48px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  outline: "none",
  display: "flex",
  flexDirection: "column",
  padding: "20px",
};

const closeButtonStyle = {
  position: "absolute",
  top: "8px",
  right: "8px",
  color: "black",
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
};

const editorContainerStyle = {
  flexGrow: 1,
  marginBottom: "20px", // Separación entre el editor y el botón
  border: "none",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
};

export default function ModalEmail({ open, handleClose, handleSendEmail }) {
  const [isAgree, setIsAgree] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [maxHeight, setMaxHeight] = useState(600);
  const modalRef = useRef(null);

  useEffect(() => {
    if (modalRef.current) {
      const availableHeight = modalRef.current.clientHeight - 300; // Ajustar según los elementos adicionales en el modal
      setMaxHeight(availableHeight);
    }
  }, [open]);

  const onSend = () => {
    handleSendEmail(isAgree, subject, message);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle} ref={modalRef}>
        <Button
          onClick={handleClose}
          className={styles.closeButton}
          style={closeButtonStyle}
        >
          <AiOutlineClose />
        </Button>
        <Typography variant="h6" component="h2">
          ¿Estás de acuerdo con la propuesta?
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
          <Typography>No</Typography>
          <Switch
            checked={isAgree}
            onChange={(e) => setIsAgree(e.target.checked)}
            color="primary"
          />
          <Typography>Si</Typography>
        </Box>
        <TextField
          label="Asunto"
          fullWidth
          margin="normal"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <Box sx={editorContainerStyle}>
          <ResizableBox
            width={"100%"}
            height={200} // Altura inicial
            minConstraints={[100, 200]}
            maxConstraints={[Infinity, maxHeight]}
            resizeHandles={["s"]}
            style={{ overflow: "hidden" }}
          >
            <ReactQuill
              value={message}
              onChange={setMessage}
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }, { font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image", "video"],
                  ["clean"],
                ],
              }}
              formats={[
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "video",
              ]}
              style={{
                height: "100%",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
            />
          </ResizableBox>
        </Box>
        <Box sx={buttonContainerStyle}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSend}
            className={styles.sendButton}
          >
            <span>Enviar</span> <IoMdSend />
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
