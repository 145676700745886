import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router-dom"; // Importa useNavigate
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  PageSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { FiDownload, FiTrash } from "react-icons/fi";
import styles from "../../../../../Crear Contrato/AnexosModal.module.css";
import { useMutation, gql, useQuery } from "@apollo/client";
import Fallbackmini from "../../../../../../../assets/Animations/Fallbackmini";

const UPLOAD_ANEXO2 = gql`
  mutation UploadAnexo2($file: Upload!, $decodedDocumentKey: String!) {
    uploadAnexo2(file: $file, documentKey: $decodedDocumentKey) {
      success
      message
    }
  }
`;

const OBTENER_ANEXOS = gql`
  query ObtenerAnexospendientes($decodedDocumentKey: String!) {
    obtenerAnexospendientes(documentKey: $decodedDocumentKey) {
      titulo
      enlace
    }
  }
`;

const DELETE_ANEXO = gql`
  mutation DeleteAnexo($decodedDocumentKey: String!, $filename: String!) {
    deleteAnexo(documentKey: $decodedDocumentKey, filename: $filename) {
      success
      message
    }
  }
`;

export default function AnexosModal({ onClose, decodedDocumentKey }) {
  const [existingDocuments, setExistingDocuments] = useState([]); // Archivos existentes
  const [newDocuments, setNewDocuments] = useState([]); // Archivos nuevos
  const fileInputRef = useRef(null);

  const [deleteAnexo, { loading: deleting, error: deleteError }] = useMutation(
    DELETE_ANEXO,
    {
      onCompleted: () => {
        refetch(); // Refetch los anexos después de una eliminación exitosa
      },
    }
  );

  const {
    data: dataquery,
    loading: loadingquery,
    error: errorquery,
    refetch,
  } = useQuery(OBTENER_ANEXOS, {
    variables: { decodedDocumentKey },
  });

  if (errorquery) {
    console.error("Error al cargar anexos:", errorquery);
  }

  useEffect(() => {
    if (dataquery && dataquery.obtenerAnexospendientes) {
      setExistingDocuments(
        dataquery.obtenerAnexospendientes.map((doc) => ({
          ...doc,
          name: doc.titulo, // Asegúrate de que el nombre se asigna desde titulo
          enlace: doc.enlace, // Enlace del archivo existente
          id: doc.titulo, // Usar el título como ID por simplicidad
        }))
      );
    }
  }, [dataquery]);

  const [uploadAnexo2, { data, loading, error }] = useMutation(UPLOAD_ANEXO2, {
    onCompleted: () => {
      refetch(); // Refetch los anexos después de una subida exitosa
    },
  });

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const handleSaveAnexos = async () => {
    if (!decodedDocumentKey) {
      console.log("Document key is missing.");
      setFeedbackMessage("Document key is missing.");
      setIsError(true);
      return;
    }

    console.log("Saving annexes for new documents", newDocuments);
    for (let doc of newDocuments) {
      const fileToSend = doc.file; // Directamente el archivo real
      console.log("Uploading file:", fileToSend.name);
      try {
        const { data } = await uploadAnexo2({
          variables: { file: fileToSend, decodedDocumentKey },
        });
        console.log("Upload response:", data);
        if (data.uploadAnexo2.success) {
          console.log("Anexo saved successfully.");
          setFeedbackMessage("Anexo guardado con éxito.");
          setIsError(false);
        } else {
          console.error("Error saving anexo:", data.uploadAnexo2.message);
          setFeedbackMessage(
            data.uploadAnexo2.message || "Error al guardar el anexo."
          );
          setIsError(true);
        }
      } catch (error) {
        console.error("Communication error:", error);
        setFeedbackMessage(
          error.message || "Error al comunicarse con el servidor."
        );
        setIsError(true);
      }
    }

    // Limpiar después de guardar
    setNewDocuments([]);
    refetch();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const newDocument = {
      name: file.name,
      file: file,
      enlace: URL.createObjectURL(file), // URL de previsualización si es necesario
      id: file.name,
    };

    setNewDocuments((prevDocs) => [...prevDocs, newDocument]);
  };

  const downloadDocument = (url, fileName) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName || "download";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const downloadButtonTemplate = (props) => (
    <button
      onClick={() => downloadDocument(props.enlace, props.name)} // Usa 'name' para el nombre del archivo
      className={styles.iconButton}
    >
      <FiDownload />
    </button>
  );

  const handleDeleteDocument = (filename) => {
    deleteAnexo({ variables: { decodedDocumentKey, filename } });
  };

  const deleteButtonTemplate = (props) => (
    <button
      onClick={() => handleDeleteDocument(props.name)}
      className={styles.iconButton}
    >
      <FiTrash />
    </button>
  );

  useEffect(() => {
    if (deleteError) {
      console.error("Error al eliminar el anexo:", deleteError);
    }
  }, [deleteError]);

  return ReactDOM.createPortal(
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <button onClick={onClose} className={styles.closeButton}>
          X
        </button>
        <h2>Añadir Anexos</h2>
        <button
          onClick={() => fileInputRef.current.click()}
          className={styles.addAttachmentButton}
        >
          + Añadir Anexo
        </button>
        <input
          type="file"
          onChange={handleFileUpload}
          ref={fileInputRef}
          style={{ display: "none" }}
        />

        <div className={`control-pane ${styles.dataGridContainer}`}>
          <div className="control-section">
            {loadingquery ? (
              <Fallbackmini /> // Mostrar Fallbackmini mientras se cargan los datos
            ) : (
              <GridComponent
                dataSource={[...existingDocuments, ...newDocuments]} // Combina ambos tipos de documentos
                allowPaging={true}
                pageSettings={{ pageCount: 5 }}
                height="200px"
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="name"
                    headerText="Anexo"
                    width="auto"
                  />
                  <ColumnDirective
                    headerText="Descargar"
                    template={downloadButtonTemplate}
                    width="auto"
                  />
                  <ColumnDirective
                    headerText="Eliminar"
                    template={deleteButtonTemplate}
                    width="auto"
                  />
                </ColumnsDirective>
              </GridComponent>
            )}
          </div>
        </div>
        <button
          onClick={handleSaveAnexos}
          className={styles.saveButton}
          disabled={loading || deleting} // Deshabilitar botón mientras se carga o elimina
        >
          Guardar Anexos
          {(loading || deleting) && <Fallbackmini />}
        </button>

        {feedbackMessage && (
          <div
            className={isError ? styles.messageError : styles.messageSuccess}
          >
            {feedbackMessage}
          </div>
        )}
      </div>
    </div>,
    document.body
  );
}
