import React, { createContext, useContext, useEffect, useState } from "react";
import {
  Navigate,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./assets/styles/App.css";
import { Authenticator } from "@aws-amplify/ui-react";
import store from "./store"; // Importación por defecto
import { Provider } from "react-redux";
import HomeRoutes from "./Routes/Home-routes";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { fetchAuthSession } from "@aws-amplify/auth";
import { Amplify } from "aws-amplify";

const config = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  // Añade aquí más configuraciones si es necesario
};

Amplify.configure(config);

// URL de tu API de GraphQL
const GRAPHQL_URI = "https://safecon.app/graphql";

const uploadLink = createUploadLink({
  uri: GRAPHQL_URI, // Usa tu propio endpoint aquí
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const session = await fetchAuthSession(); // Fetch the authentication session
    const accessToken = session.tokens.accessToken.toString();
    const idToken = session.tokens.idToken.toString();
    console.log("Access Token:", session.tokens.accessToken.toString());
    console.log("ID Token:", session.tokens.idToken.toString());
    // Agrega ambos tokens en los headers si necesitas enviar ambos
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
        "X-ID-Token": idToken,
      },
    };
  } catch (error) {
    console.error("Error getting the auth tokens", error);
    return { headers }; // Continúa sin los tokens si ocurre un error
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${JSON.stringify(path)}`
      )
    );
  if (networkError) {
    console.log(`[Network error]: ${JSON.stringify(networkError)}`);
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, uploadLink]), // Encadena authLink con httpLink
  cache: new InMemoryCache(),
});

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // O 'instant' en navegadores que lo soporten.
    });
  }, [pathname]); // Dependencia actualizada a pathname para mejor claridad

  return null;
}

const ScrollToTopOnRefresh = () => {
  if ("scrollRestoration" in window.history) {
    window.history.scrollRestoration = "manual";
  }

  return null;
};

export default function App() {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Router>
          <ScrollToTopOnRefresh />
          <ScrollToTop />
          <HomeRoutes />
        </Router>
      </Provider>
    </ApolloProvider>
  );
}
