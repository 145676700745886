import React from "react";
import styles from "./Privacy.module.css"; // Importamos el CSS modular

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Política de Privacidad de SafeCon</h1>
      <p className={styles.lastUpdated}>
        Última modificación: 29 de abril de 2024
      </p>
      <section>
        <h2 className={styles.subheader}>1. Introducción</h2>
        <p>
          SafeCon ("SafeCon Law SL") valora la privacidad y la confidencialidad
          de la información personal de sus usuarios. Esta Política de
          Privacidad explica cómo recopilamos, usamos, protegemos y compartimos
          la información personal y los datos contractuales que usted
          proporciona al utilizar nuestro software y servicios. Estamos
          comprometidos con el cumplimiento del Reglamento General de Protección
          de Datos de la Unión Europea ("RGPD") y la Ley Orgánica de Protección
          de Datos Personales y garantía de los derechos digitales ("LOPDGDD").
        </p>
      </section>
      <section>
        <h2 className={styles.subheader}>
          2. Datos Personales que Recopilamos
        </h2>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Información de Contacto: Nombres, direcciones de correo electrónico,
            números de teléfono y direcciones postales.
          </li>
          <li className={styles.listItem}>
            Información de la Cuenta: Nombres de usuario, contraseñas e
            información de facturación.
          </li>
          <li className={styles.listItem}>
            Datos de Uso: Información sobre cómo utiliza nuestros servicios,
            incluidas las funciones activadas, la frecuencia de uso y la
            duración de las sesiones.
          </li>
          <li className={styles.listItem}>
            Datos del Contrato y Anexos: Detalles sobre los contratos y
            documentos anexos que gestiona a través de nuestros servicios,
            incluyendo las partes involucradas, objeto del contrato, términos, y
            documentos relacionados. Todos los contratos y anexos se tratan de
            manera confidencial y se asegura que solo el propio usuario tiene
            acceso a estos documentos.
          </li>
        </ul>
      </section>
      <section>
        <h2 className={styles.subheader}>3. Uso de Información Personal</h2>
        <p>
          Proporcionar y Mejorar Nuestros Servicios: Utilizamos su información
          personal para entregar y mejorar nuestros servicios.
        </p>
        <p>
          Comunicación: Para enviarle actualizaciones y comunicaciones sobre
          nuestros servicios y su cuenta.
        </p>
        <p>
          Cumplimiento Legal y Regulatorio: Cumplir con nuestras obligaciones
          legales y responder a solicitudes legales.
        </p>
      </section>
      <section>
        <h2 className={styles.subheader}>
          4. Confidencialidad y Compartición de Información Personal
        </h2>
        <p>
          Proveedores de Servicios: Solo compartimos información necesaria con
          proveedores que nos ayudan a operar y mejorar nuestros servicios bajo
          estrictos términos de confidencialidad.
        </p>
        <p>
          Asesores Legales y Financieros: En caso de una disputa legal o
          auditoría financiera, podemos compartir información necesaria para la
          resolución del asunto.
        </p>
        <p>
          Autoridades Gubernamentales: Compartimos información personal cuando
          es requerido por ley, siempre bajo un proceso legal adecuado.
        </p>
      </section>
      <section>
        <h2 className={styles.subheader}>5. Derechos de Privacidad</h2>
        <p>
          Puede acceder, rectificar, eliminar o limitar el uso de su información
          personal. Tiene derecho a oponerse al tratamiento de sus datos y
          solicitar su portabilidad. Contacte con nosotros a través de
          [safeconlaw@gmail.com] para ejercer estos derechos.
        </p>
      </section>
      <section>
        <h2 className={styles.subheader}>6. Seguridad de la Información</h2>
        <p>
          Implementamos medidas de seguridad técnicas y organizativas para
          proteger su información personal contra el acceso no autorizado, la
          pérdida y la destrucción. No obstante, ninguna medida es completamente
          infalible.
        </p>
      </section>
      <section>
        <h2 className={styles.subheader}>7. Retención de Datos</h2>
        <p>
          Retenemos su información personal solo por el tiempo necesario para
          cumplir con los propósitos establecidos, a menos que se requiera un
          período más largo por ley.
        </p>
      </section>
      <section>
        <h2 className={styles.subheader}>
          8. Cambios en la Política de Privacidad
        </h2>
        <p>
          Nos reservamos el derecho de modificar esta política. Los cambios
          significativos serán comunicados a través de nuestro sitio web o por
          correo electrónico.
        </p>
      </section>
      <section>
        <h2 className={styles.subheader}>9. Contacto</h2>
        <p>
          Si tiene preguntas sobre esta política o sobre cómo gestionamos su
          información personal, contáctenos en safeconlaw@gmail.com.
        </p>
      </section>
      <section>
        <h2 className={styles.subheader}>10. Aceptación de la Política</h2>
        <p>
          Al usar nuestros servicios, usted acepta las prácticas descritas en
          esta política de privacidad.
        </p>
      </section>
      <section>
        <h2 className={styles.subheader}>
          11. Disposiciones Adicionales para España
        </h2>
        <p>
          Delegado de Protección de Datos: El Delegado de Protección de Datos
          supervisa nuestro cumplimiento del RGPD y la LOPDGDD. Contacto:
          safeconlaw@gmail.com.
        </p>
        <p>
          Derechos Adicionales: Tiene derecho a presentar una reclamación ante
          la Agencia Española de Protección de Datos si cree que sus derechos no
          han sido respetados.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
