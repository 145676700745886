import React, { useRef, Suspense, lazy } from "react";
import {
  Navigate,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import "@aws-amplify/ui-react/styles.css";

import logo from "../assets/images/Logo_420.webp";
import {
  Authenticator,
  useTheme,
  View,
  Image,
  Text,
  Heading,
  Button,
} from "@aws-amplify/ui-react";

import Fallback from "../assets/Animations/Fallback";
import Banner from "../pages/Home/Landing/Banner";
import PrivacyPolicy from "../pages/Home/Landing/Privacy";
import InvitationHandler from "../pages/Signup/InvitationHandler";
import NotFound from "../pages/Signup/NotFound";
import Weblink from "../pages/Dashboard/Mis contratos/Cuadros de mando/Componentesrevision/Negociacion/Weblink/Weblink";
import IframeFirmaMail from "../pages/Dashboard/Mis contratos/Cuadros de mando/Componentesrevision/Docusign/IframeFirmaMail";
import LandingFirmaMail from "../pages/Dashboard/Mis contratos/Cuadros de mando/Componentesrevision/Docusign/LandingFirmaMail";
import ProtectedRoute from "./ProtectedAPIroute";

const RegistrationController = lazy(() =>
  import("../pages/Signup/RegistrationController")
);
// Lazy imports
const AppHeader = lazy(() => import("../pages/Header/WebHeaderRender"));
const HomeHeader = lazy(() => import("../pages/Home/Landing/HomeHeader"));
const HomePipeline = lazy(() => import("../pages/Home/Landing/HomePipeline"));
const Privacidad = lazy(() => import("../pages/Home/Landing/Privacidad"));
const Footer = lazy(() => import("../pages/Home/Landing/Footer"));
const InfoAPI = lazy(() =>
  import("../pages/Home/API-home/Conoce nuestra API/InfoAPI")
);

const ElaboracionHome = lazy(() =>
  import("../pages/Home/CLM/Elaboracion de contratos/ElaboracionHome")
);
const DashboardRoutes = lazy(() => import("../Routes/Dashboard-routes"));
const CLM = lazy(() =>
  import("../pages/Home/CLM/Contract Lifecycle Management/CLM")
);
const CLMList = lazy(() =>
  import("../pages/Home/CLM/Contract Lifecycle Management/CLM-List")
);
const Neghome = lazy(() =>
  import("../pages/Home/CLM/Negociacion de contratos/Neghome")
);
const MockupNeg = lazy(() =>
  import("../pages/Home/CLM/Negociacion de contratos/MockupNeg")
);
const Negexplain = lazy(() =>
  import("../pages/Home/CLM/Negociacion de contratos/Negexplain")
);
const Firmahome = lazy(() =>
  import("../pages/Home/CLM/Firma electronica/Firmahome")
);
const Almacenamiento = lazy(() =>
  import("../pages/Home/CLM/Almacenamiento de contratos/Almacenamiento")
);
const Firma2 = lazy(() => import("../pages/Home/CLM/Firma electronica/Firma2"));
const Firma15 = lazy(() =>
  import("../pages/Home/CLM/Firma electronica/Firma1.5")
);
const Mockalm = lazy(() =>
  import("../pages/Home/CLM/Almacenamiento de contratos/Mockalm")
);
const Almex = lazy(() =>
  import("../pages/Home/CLM/Almacenamiento de contratos/Almex")
);
const Almintegraciones = lazy(() =>
  import("../pages/Home/CLM/Almacenamiento de contratos/Almintegraciones")
);
const Pricing = lazy(() => import("../pages/Home/Pricing/Pricing"));
const Rentabilidad = lazy(() => import("../pages/Home/Landing/Rentabilidad"));
const Integraciones = lazy(() => import("../pages/Home/Landing/Integraciones"));
const Quienessomos = lazy(() =>
  import("../pages/Home/Acercade.js/Quienessomos/Quienessomos")
);
const Sostenibilidad = lazy(() =>
  import("../pages/Home/Acercade.js/Sostenibilidad/Sostenibilidad")
);
const Sostenibilidad2 = lazy(() =>
  import("../pages/Home/Acercade.js/Sostenibilidad/Sostenibilidad2")
);
const Sostenibilidad3 = lazy(() =>
  import("../pages/Home/Acercade.js/Sostenibilidad/Sostenibilidad3")
);
const Diagrama = lazy(() => import("../pages/Home/Landing/Diagrama"));
const APIhome = lazy(() =>
  import("../pages/Home/API-home/Conoce nuestra API/APIhome")
);
const APIex = lazy(() =>
  import("../pages/Home/API-home/Conoce nuestra API/APIex")
);
const APIAnalytics = lazy(() =>
  import("../pages/Home/API-home/Conoce nuestra API/APIAnalytics")
);
const APIBlockchain = lazy(() =>
  import("../pages/Home/API-home/Conoce nuestra API/APIBlockchain")
);
const Rendimiento = lazy(() =>
  import("../pages/Home/Analytics/Informes de rendimiento/Rendimiento")
);
const Predictivos = lazy(() =>
  import("../pages/Home/Analytics/Informes predictivos/Predictivos")
);
const Rendimiento2 = lazy(() =>
  import("../pages/Home/Analytics/Informes de rendimiento/Rendimiento2")
);
const Rendimiento3 = lazy(() =>
  import("../pages/Home/Analytics/Informes de rendimiento/Rendimiento3")
);
const Predictivos2 = lazy(() =>
  import("../pages/Home/Analytics/Informes predictivos/Predictivos2")
);
const Predictivos3 = lazy(() =>
  import("../pages/Home/Analytics/Informes predictivos/Predictivos3")
);
const IconosEditor = lazy(() =>
  import("../pages/Home/CLM/Elaboracion de contratos/IconosEditor")
);
const Demo = lazy(() => import("../pages/Home/Landing/Demo"));
const ScrollToTopButton = lazy(() =>
  import("../pages/Header/ScrollToTopButton")
);

const SolicitarPlan = lazy(() => import("../pages/Home/Pricing/SolicitarPlan"));

const Emailvalidation = lazy(() => import("../pages/Signup/Emailvalidation"));
const Success = lazy(() =>
  import(
    "../pages/Dashboard/Mis contratos/Cuadros de mando/Componentesrevision/Negociacion/Weblink/Success"
  )
);

const formFields = {
  signIn: {
    username: {
      placeholder: "usuario@dominio.com", // Ejemplo de placeholder personalizado
      label: "Email", // Cambiar "Username" por "Email"
      inputProps: {
        // Asegurar que el tipo de input sea email para validación
        type: "email",
      },
    },
  },

  forgotPassword: {
    username: {
      label: "Email", // Cambia "Username" por "Email" si utilizas emails como usernames
      placeholder: "Tu correo electrónico",
      inputProps: {
        // Asegúrate de que el navegador entienda que es un campo de email
        type: "email",
        required: true, // Marcar como campo obligatorio
      },
    },
  },
  resetPassword: {
    // Asume que estamos personalizando también los campos de la página de reseteo
    confirmation_code: {
      label: "Código de Confirmación",
      placeholder: "Introduce el código recibido",
    },
    password: {
      label: "Nueva Contraseña",
      placeholder: "Introduce tu nueva contraseña",
    },
  },

  confirmVerifyUser: {
    confirmation_code: {
      label: "New Label",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
};

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="SafeCon logo" src={logo} width={200} />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },
};

const GraphQLPlayground = () => {
  window.location.href = "/graphql"; // Redirige al playground predeterminado
  return null;
};

export default function HomeRoutes() {
  const feesRef = useRef(null);

  return (
    <>
      <Suspense fallback={<Fallback />}>
        <Routes>
          <Route
            path="/graphql"
            element={
              <Authenticator hideSignUp={true}>
                <ProtectedRoute />
              </Authenticator>
            }
          />
          <Route
            path="/CLM"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <CLM />

                <CLMList />
                <Footer />
              </>
            }
          />

          <Route
            path="/elaboracion-home"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <ElaboracionHome />
                <IconosEditor />
                <Footer />
              </>
            }
          />
          <Route
            path="/negociacion-home"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <Neghome />
                <MockupNeg />
                <Negexplain />
                <Footer />
              </>
            }
          />
          <Route
            path="/firma-home"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <Firmahome />
                <Firma15 />
                <Firma2 />

                <Footer />
              </>
            }
          />
          <Route
            path="/almacenamiento-home"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <Almacenamiento />
                <Mockalm />
                <Almex />
                <Almintegraciones />
                <Footer />
              </>
            }
          />

          <Route
            path="/analytics-informes-rendimiento"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <Rendimiento />
                <Rendimiento2 />
                <Rendimiento3 />
                <Footer />
              </>
            }
          />

          <Route
            path="/analytics-informes-predictivos"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <Predictivos />
                <Predictivos2 />
                <Predictivos3 />

                <Footer />
              </>
            }
          />

          <Route
            path="/info-api"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <APIhome />
                <InfoAPI />
                <APIex />
                <APIAnalytics />
                <APIBlockchain />
                <Integraciones />
                <Footer />
              </>
            }
          />
          <Route
            path="/pricing"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <Pricing />
                <Footer />
              </>
            }
          />
          <Route
            path="/acercade-quienes-somos"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <Quienessomos />
                <Footer />
              </>
            }
          />
          <Route
            path="/acercade-sostenibilidad"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <Sostenibilidad />
                <Sostenibilidad2 />
                <Sostenibilidad3 />
                <Footer />
              </>
            }
          />

          <Route
            path="/solicitar-demo"
            element={
              <>
                <Demo />
                <Footer />
              </>
            }
          />
          <Route
            path="/solicitar-plan"
            element={
              <>
                <SolicitarPlan />
                <Footer />
              </>
            }
          />

          <Route
            path="/handle-invitation"
            element={
              <>
                <InvitationHandler />
                <Footer />
              </>
            }
          />
          <Route
            path="/ver-propuesta"
            element={
              <>
                <Weblink />
              </>
            }
          />
          <Route
            path="/contrapropuesta-enviada"
            element={
              <>
                <Success />
              </>
            }
          />
          <Route
            path="firmar-contrato"
            element={
              <>
                <IframeFirmaMail />
              </>
            }
          />

          <Route
            path="landing-contrato-firmado-mail"
            element={
              <>
                <LandingFirmaMail />
              </>
            }
          />

          <Route
            path="/404"
            element={
              <>
                <NotFound />
                <Footer />
              </>
            }
          />
          <Route
            path="/politica-privacidad"
            element={
              <>
                <PrivacyPolicy />
              </>
            }
          />
          <Route path="/emailvalidation" element={<Emailvalidation />} />
          <Route path="/signup" element={<RegistrationController />} />

          <Route
            path="/dashboard/*"
            element={
              <Authenticator
                formFields={formFields}
                components={components}
                hideSignUp={true}
              >
                <DashboardRoutes />
              </Authenticator>
            }
          />

          <Route
            path="/"
            element={
              <>
                <AppHeader />
                <ScrollToTopButton />
                <HomeHeader feesRef={feesRef} />
                <Banner />

                <HomePipeline />
                <Diagrama />
                <Rentabilidad />
                <Integraciones />
                <Privacidad />
                <Footer />
              </>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
}
