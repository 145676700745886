import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Button } from "@mui/material";
import styles from "./LandingFirmaMail.module.css";

const LandingFirmaMail = () => {
  const navigate = useNavigate();

  const handleBackToContract = () => {
    if (window.top !== window.self) {
      window.top.location.href = "/";
    } else {
      navigate(`/`, { replace: true });
    }
  };

  return (
    <div className={styles.successPage}>
      <div className={styles.wrapper}>
        <AiOutlineCheckCircle className={styles.successIcon} />
        <h2>Proceso terminado</h2>
        <Button
          variant="contained"
          color="primary"
          onClick={handleBackToContract}
          className={styles.backButton}
        >
          Salir
        </Button>
      </div>
    </div>
  );
};

export default LandingFirmaMail;
