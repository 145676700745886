import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useLocation } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import Fallback from "../../../../../../assets/Animations/Fallback";
const GET_SIGNING_URL = gql`
  mutation GetSigningUrl($signingInfo: [SigningUrlInput!]!) {
    getSigningUrl(signingInfo: $signingInfo) {
      success
      message
      signingUrls
    }
  }
`;

const SignDocument = () => {
  const [tokenData, setTokenData] = useState(null);
  const [email, setEmail] = useState(null);
  const [signingUrl, setSigningUrl] = useState(null);

  const location = useLocation();
  const [getSigningUrl] = useMutation(GET_SIGNING_URL);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get("token");
    const email = query.get("email");

    if (token && email) {
      try {
        const decoded = jwtDecode(token);
        setTokenData(decoded);
        setEmail(email);

        const signingInfo = {
          envelopeId: decoded.envelope_id,
          clientUserId: decoded.client_user_id, //solo envelopeid para correcturl
          recipientId: decoded.recipient_id,
          userName: decoded.name,
          email: decoded.email,
        };

        getSigningUrl({ variables: { signingInfo: [signingInfo] } })
          .then((response) => {
            if (response.data.getSigningUrl.success) {
              setSigningUrl(response.data.getSigningUrl.signingUrls[0]);
            } else {
              console.error(response.data.getSigningUrl.message);
            }
          })
          .catch((error) => {
            console.error("Error fetching signing URL:", error);
          });
      } catch (error) {
        console.error("Invalid token:", error);
      }
    }
  }, [location, getSigningUrl]);

  return (
    <div>
      {signingUrl ? (
        <iframe
          src={signingUrl}
          width="100%"
          height="900px"
          title="Sign Document"
        />
      ) : (
        <Fallback />
      )}
    </div>
  );
};

export default SignDocument;
