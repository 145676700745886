import React, { useState, useRef, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import styles from "./Weblink.module.css";
import Fallback from "../../../../../../../assets/Animations/Fallback";
import {
  DocumentEditorContainerComponent,
  Toolbar,
  WordExport,
  Inject,
} from "@syncfusion/ej2-react-documenteditor";
import { saveAs } from "file-saver";
import AnexosModal from "./AnexosModalWeblink";
import logo from "../../../../../../../assets/images/Logo2.webp";
import {
  IoArrowForwardCircleOutline,
  IoArrowBackCircleOutline,
} from "react-icons/io5"; // Importa las flechas de react-icons
import { useQuery, gql, useMutation } from "@apollo/client";
import ModalEnviarContrapropuesta from "./ModalEnviarContrapropuesta";

const GET_DOCUMENT = gql`
  query GetDocument($documentKey: String!) {
    getDocument(documentKey: $documentKey) {
      documentKey
      content
    }
  }
`;

const SEND_CONTRAPROPOSAL = gql`
  mutation SendContraproposal(
    $input: ContraproposalInput!
    $sfdtFile: Upload!
  ) {
    sendContraproposal(input: $input, sfdtFile: $sfdtFile) {
      success
      message
    }
  }
`;

const CHECK_TOKEN_EXPIRED = gql`
  query IsTokenExpired($token: String!) {
    isTokenExpired(token: $token)
  }
`;

const MyEditor = ({ onRefReady, documentContent, usuario }) => {
  const customToolbarItems = [
    "Undo",
    "Redo",
    "Separator",
    "Image",
    "Table",
    "Hyperlink",
    "Bookmark",
    "TableOfContents",
    "Separator",
    "Header",
    "Footer",
    "PageSetup",
    "PageNumber",
    "Break",
    "Separator",
    "Find",
    "Separator",
    "Comments",
    "TrackChanges",
    "Separator",
    "LocalClipboard",
    "Separator",
    "FormFields",
    "UpdateFields",
  ];

  const editorRef = useRef(null);

  const convertClipboardContentToText = async () => {
    try {
      const clipboardItems = await navigator.clipboard.read();
      let text = "";

      for (const item of clipboardItems) {
        if (item.types.includes("text/html")) {
          const blob = await item.getType("text/html");
          const html = await blob.text();
          // Convertir HTML a texto plano
          const doc = new DOMParser().parseFromString(html, "text/html");
          text = doc.body.innerText;

          // Escribir el texto convertido de vuelta en el portapapeles
          await navigator.clipboard.writeText(text);
        } else if (item.types.includes("text/plain")) {
          const blob = await item.getType("text/plain");
          text = await blob.text();
        }
      }

      console.log("Clipboard content converted to text and updated:", text);
    } catch (error) {
      console.error("Error converting clipboard content:", error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      convertClipboardContentToText();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current.documentEditor;
      editor.enableLocalPaste = false; // Permitir pegar desde el portapapeles del sistema operativo
      editor.currentUser = usuario;
      editor.userColor = "#FF4081";
      editor.serviceUrl = "http://localhost:6002/api/documenteditor";

      if (onRefReady) {
        onRefReady(editorRef);
      }

      if (documentContent) {
        editor.open(documentContent);
      }

      // Añadir event listener para Ctrl+V
      const handlePaste = async (event) => {
        if (event.ctrlKey && event.key === "v") {
          event.preventDefault();
          if (!document.hasFocus()) {
            console.warn("Document is not focused.");
            return;
          }

          try {
            // Solicitar permiso para acceder al portapapeles
            const permission = await navigator.permissions.query({
              name: "clipboard-read",
            });
            if (permission.state === "denied") {
              console.warn("Permission to read clipboard denied");
              return;
            }

            // Leer el contenido del portapapeles
            const text = await navigator.clipboard.readText();

            // Realizar la solicitud al microservicio
            const response = await fetch(
              "http://localhost:6002/api/documenteditor/SystemClipboard",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  content: text, // Contenido del portapapeles convertido
                  type: "txt", // Ajusta esto según el tipo de contenido
                }),
              }
            );

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Manejar la respuesta del servidor
            const result = await response.json();
            if (result) {
              editor.paste(result);
            }
          } catch (error) {
            console.error("Error fetching clipboard content:", error);
          }
        }
      };

      // Añadir el event listener
      document.addEventListener("keydown", handlePaste);

      // Limpiar el event listener cuando el componente se desmonte
      return () => {
        document.removeEventListener("keydown", handlePaste);
      };
    }
  }, [editorRef, usuario, documentContent, onRefReady]);

  return (
    <>
      <div className={styles.header}>
        <img src={logo} alt="Logo" className={styles.logo} />
      </div>
      <DocumentEditorContainerComponent
        ref={editorRef}
        enableToolbar={true}
        toolbarItems={customToolbarItems}
        height="91vh"
        userColor="#eaeaea"
      >
        <Inject services={[Toolbar, WordExport]} />
      </DocumentEditorContainerComponent>
    </>
  );
};

const Weblink = () => {
  //*****OBTENCIÓN Y DECODIFICACIÓN DEL TOKEN DE NEGOCIACIÓN*****
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const [decodedToken, setDecodedToken] = useState(null);
  const [isRefetching, setIsRefetching] = useState(false);
  //Verificación de protección para comprobar si el token está expirado o no

  const {
    data,
    loading: tokenLoading,
    error,
    refetch: refetchToken,
  } = useQuery(CHECK_TOKEN_EXPIRED, {
    variables: { token },
    skip: !token,
  });

  useEffect(() => {
    if (data && data.isTokenExpired) {
      navigate("/404", { replace: true });
    }
  }, [data, navigate]);

  useEffect(() => {
    if (token) {
      try {
        console.log("El token es:", token);
        console.log("El tipo del token es:", typeof token);
        const decoded = jwtDecode(token);
        setDecodedToken(decoded);
        console.log(decoded); // Para ver los atributos en la consola
      } catch (error) {
        console.error("Error decoding token:", error);
        navigate("/404"); // Redirigir a la página 404 si el token no es válido
      }
    } else {
      console.error("Missing token parameter");
      navigate("/404"); // Redirigir a la página 404 si falta el token
    }
  }, [token, navigate]);

  // Desestructurar los atributos del token decodificado
  const { documentKey, titulo, emailEmisor, emailReceptor, versionId } =
    decodedToken || {};

  // Crear documentKeyAnexos
  const documentKeyAnexos = documentKey
    ? documentKey.substring(0, documentKey.indexOf(".sfdt") + 5)
    : "";

  const documentKeyAnexosString = String(documentKeyAnexos);

  useEffect(() => {
    const documentKeyAnexosString = String(documentKeyAnexos);
    console.log("Tipo de documentKeyAnexos:", typeof documentKeyAnexosString);
    console.log("Valor de documentKeyAnexos:", documentKeyAnexosString);
  }, [documentKeyAnexos]);
  //****EDITOR AJUSTES */

  const [cliente, setCliente] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [editorRef, setEditorRef] = useState(null);
  const [isMutatting, setIsMuttating] = useState(false);
  const [documentContent, setDocumentContent] = useState(null);
  const [showAnexosModal, setShowAnexosModal] = useState(false);

  //Query get document de la propuesta

  const {
    data: documentData,
    loading: loadingDocument,
    error: errorDocument,
    refetch,
  } = useQuery(GET_DOCUMENT, {
    variables: { documentKey },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (documentData && documentData.getDocument) {
      const decodedContent = atob(documentData.getDocument.content);
      try {
        const content = JSON.parse(decodedContent);
        setDocumentContent(content);

        if (editorRef.current) {
          editorRef.current.documentEditor.open(content);
        }
      } catch (error) {
        console.error("Error al decodificar el documento:", error);
      }
    }
  }, [documentData, editorRef, navigate, data]);

  const openAnexosModal = () => {
    if (!documentKey) {
      alert("Clave del documento no está disponible.");
      return;
    }

    setShowAnexosModal(true);
  };

  const handleRef = useCallback((ref) => {
    setEditorRef(ref);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const saveAsWord = () => {
    if (editorRef.current) {
      let documentEditor = editorRef.current.documentEditor;
      documentEditor.saveAsBlob("Docx").then((blob) => {
        saveAs(blob, "Documento.docx");
      });
    }
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("files", file);
      fetch("http://localhost:6002/api/documenteditor/Import", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (editorRef.current) {
            editorRef.current.documentEditor.open(data);
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  };

  //FUNCION PARA ENVIAR CONTRAPROPUESTA
  const [sendContraproposal] = useMutation(SEND_CONTRAPROPOSAL);

  const [showEmailModal, setShowEmailModal] = useState(false);
  const openEmailModal = () => {
    setShowEmailModal(true);
  };
  const closeEmailModal = () => {
    setShowEmailModal(false);
  };

  const [isSending, setIsSending] = useState(false);

  const [receptor, setReceptor] = useState("");
  const handleSendEmail = (isAgree, subject, message) => {
    if (editorRef.current) {
      const documentEditor = editorRef.current.documentEditor;
      setIsSending(true);
      // Guardar como SFDT
      documentEditor.saveAsBlob("Sfdt").then((sfdtBlob) => {
        const sfdtFile = new Blob([sfdtBlob], {
          type: "application/json",
        });
        const sfdtFileToSend = new File([sfdtFile], "propuesta.sfdt");

        const contraproposalInput = {
          documentKey,
          emailEmisor,
          emailReceptor,
          asunto: subject,
          mensaje: message,
          isagree: isAgree,
          token,
          fechaEnvio: new Date().toLocaleString("es-ES", {
            year: "numeric",
            day: "2-digit",
            month: "2-digit", // Puedes eliminar esta línea si no deseas mostrar el mes
            hour: "2-digit",
            minute: "2-digit",
          }),
        };

        setReceptor(contraproposalInput.emailReceptor);

        sendContraproposal({
          variables: { input: contraproposalInput, sfdtFile: sfdtFileToSend },
        })
          .then((response) => {
            console.log("Propuesta enviada:", response.data.sendContraproposal);
            closeEmailModal();
            setIsSending(false);

            navigate("/contrapropuesta-enviada", {
              replace: true,
              state: { emailEmisor },
            }); // Navegar a la nueva ruta y reemplazar la actual en el historial
          })
          .catch((error) => {
            console.error("Error enviando la propuesta:", error);
            setIsSending(false);
          });
      });
    }
  };

  if (isSending || tokenLoading || isRefetching) return <Fallback />;
  if (error || (data && data.isTokenExpired)) return <Fallback />;

  return (
    <div>
      {decodedToken ? (
        <div className={styles.container}>
          <div
            className={`${styles.a4Container} ${
              isMenuOpen ? styles.menuOpen : ""
            }`}
          >
            <MyEditor
              onRefReady={handleRef}
              documentKey={documentKey}
              documentContent={documentContent}
              usuario={emailReceptor}
            />
          </div>
          <div
            className={`${styles.sidebarMenu} ${
              isMenuOpen ? styles.open : styles.closed
            }`}
          >
            <div className={styles.wrapper}>
              <div className={styles.menuItem}>{titulo}</div>
              <div className={styles.menuItem}>{emailEmisor}</div>
            </div>
            <button className={styles.menuButton} onClick={saveAsWord}>
              Exportar docx
            </button>
            <label className={styles.customFileUpload}>
              <input
                type="file"
                onChange={onFileChange}
                accept=".docx,.doc,.rtf,.txt,.xml,.sfdt"
                className={styles.hiddenFileInput}
              />
              Importar Documento
            </label>
            <button className={styles.menuButton} onClick={openAnexosModal}>
              Anexos
            </button>
            {showAnexosModal && (
              <AnexosModal
                onClose={() => setShowAnexosModal(false)}
                decodedDocumentKey={documentKeyAnexosString}
              />
            )}
            <button
              className={styles.menuPrimaryButton}
              onClick={openEmailModal}
            >
              Ajustar contrapropuesta
            </button>
            <div onClick={toggleMenu} className={styles.toggleButton}>
              {isMenuOpen ? (
                <IoArrowBackCircleOutline size={30} />
              ) : (
                <IoArrowForwardCircleOutline size={30} />
              )}
            </div>
          </div>
          <ModalEnviarContrapropuesta
            open={showEmailModal}
            handleClose={closeEmailModal}
            handleSendEmail={handleSendEmail}
          />
        </div>
      ) : (
        <Fallback />
      )}
    </div>
  );
};

export default Weblink;
