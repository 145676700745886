import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import styles from "./InvitationHandler.module.css"; // Importar los estilos del módulo CSS

const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation(
    $token: String!
    $nombre: String!
    $cargo: String!
  ) {
    acceptInvitation(token: $token, nombre: $nombre, cargo: $cargo) {
      success
      message
    }
  }
`;

const REJECT_INVITATION = gql`
  mutation RejectInvitation($token: String!) {
    rejectInvitation(token: $token) {
      success
      message
    }
  }
`;

const InvitationHandler = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");

  const [nombre, setNombre] = useState("");
  const [cargo, setCargo] = useState("");

  const [acceptInvitation] = useMutation(ACCEPT_INVITATION);
  const [rejectInvitation] = useMutation(REJECT_INVITATION);

  useEffect(() => {
    if (!token) {
      console.error("Missing token parameter");
      navigate("/404"); // Redirigir a la página 404
      return;
    }
  }, [token, navigate]);

  const handleAccept = () => {
    if (!nombre || !cargo) {
      alert("Debes rellenar los campos de nombre y cargo.");
      return;
    }

    acceptInvitation({ variables: { token, nombre, cargo } })
      .then(({ data }) => {
        if (data.acceptInvitation.success) {
          navigate("/dashboard");
        } else {
          alert(data.acceptInvitation.message);
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  const handleReject = () => {
    rejectInvitation({ variables: { token } })
      .then(({ data }) => {
        if (data.rejectInvitation.success) {
          navigate("/");
        } else {
          alert(data.rejectInvitation.message);
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Invitación para unirse a la empresa</h2>
      <p className={styles.description}>
        Has sido invitado para unirte a nuestra empresa. Por favor, selecciona
        una opción:
      </p>
      <input
        type="text"
        placeholder="Nombre"
        value={nombre}
        onChange={(e) => setNombre(e.target.value)}
        className={styles.input}
      />
      <input
        type="text"
        placeholder="Cargo"
        value={cargo}
        onChange={(e) => setCargo(e.target.value)}
        className={styles.input}
      />
      <button className={styles.button} onClick={handleAccept}>
        Aceptar
      </button>
      <button className={styles.button} onClick={handleReject}>
        Rechazar
      </button>
    </div>
  );
};

export default InvitationHandler;
